<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="全部" name="a" itemtype="" style="width: 100px">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge class="item">
              <span style="width: 100px">全部</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="未入库" name="b" itemtype="0">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status0" class="item">
              <span>未入库</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="已入库" name="c">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status1" class="item">
              <span>已入库</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="待发货" name="d">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status2" class="item">
              <span>待发货</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="待支付" name="e">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status3" class="item">
              <span>待支付</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="已出库" name="f">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status4" class="item">
              <span>已出库</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="已查验" name="g">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status5" class="item">
              <span>已查验</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>

      <el-tab-pane label="已签收" name="j">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status10" class="item">
              <span>已签收</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
    </el-tabs>
    <!-- 搜索部分 -->
    <div class="table-container">
      <vab-query-form style="margin: 0">
        <el-form
          ref="form"
          :model="queryForm"
          :inline="true"
          @submit.native.prevent
        >
          <el-form-item label="订单编号">
            <el-input
              v-model="queryForm.code"
              placeholder="请输入订单编号"
              clearable
            />
          </el-form-item>
          <el-form-item label="用户编号">
            <el-input
              v-model="queryForm.user_code"
              placeholder="请输入用户编号"
              clearable
            />
          </el-form-item>

          <el-form-item label="运单编号">
            <el-input
              v-model="queryForm.express_code"
              placeholder="请输入运单编号"
              clearable
            />
          </el-form-item>

          <el-form-item>
            <el-button
              icon="el-icon-search"
              type="primary"
              native-type="submit"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-plus" type="primary" @click="handleAdd">
              添加包裹
            </el-button>
            <el-button
              icon="el-icon-delete"
              type="danger"
              @click="handleDelete"
            >
              批量删除
            </el-button>
          </el-form-item>

          <el-button type="primary" @click="setQuery(5)">批量查验</el-button>
          <el-button type="primary" @click="setQuery(10)">批量签收</el-button>
          <el-button type="primary" @click="setQuery(2)">批量发货</el-button>
        </el-form>
      </vab-query-form>

      <vab-query-form style="margin-bottom: 15px">
        <div style="float: left; display: flex">
          <el-button type="primary" @click="exportForm(1)">
            CJ空运导出
          </el-button>
          <el-button type="primary" @click="exportForm(2)">
            CJ海运导出
          </el-button>
          <el-button type="primary" @click="exportForm(3)">
            EMS空运导出
          </el-button>
          <el-button type="primary" @click="exportForm(4)">
            EMS海运导出
          </el-button>
          <el-upload
            :action="uploadFileUrl"
            :on-change="handleChange"
            :show-file-list="false"
            style="margin-left: 10px"
          >
            <el-button type="primary" @click="importFile()">包裹导入</el-button>
          </el-upload>
          <el-upload
            :action="uploadFileUrl"
            :on-change="excelStatusChange"
            :show-file-list="false"
            style="margin-left: 10px"
          >
            <el-button type="primary">包裹入库</el-button>
          </el-upload>
          <el-button
            v-if="queryForm.status == 2"
            style="margin-left: 10px"
            type="primary"
            @click="laDan()"
          >
            批量拉单
          </el-button>
        </div>
      </vab-query-form>

      <!--  <vab-query-form style="margin-bottom: 15px">
        <div style="float: left">
          <el-button :loading="doPrintLoading" type="primary" @click="doPrint">
            打印面单
          </el-button>
          <el-button
            :loading="doPreviewLoading"
            type="primary"
            @click="doPreview"
          >
            打印预览
          </el-button>
          <el-button v-if="pullFormBtn" type="primary" @click="pullForm(1)">
            圆通批量拉单
          </el-button>
          <el-button v-if="pullFormBtn" type="primary" @click="pullForm(2)">
            京东批量拉单
          </el-button>
          <el-button v-if="pullFormBtn" type="primary" @click="pullForm(3)">
            顺丰批量拉单
          </el-button>
        </div>
      </vab-query-form> -->

      <el-table
        ref="tableSort"
        v-loading="listLoading"
        :data="list"
        :element-loading-text="elementLoadingText"
        :height="height"
        style="width: 100%"
        border
        @selection-change="setSelectRows"
        @sort-change="tableSortChange"
      >
        <el-table-column
          show-overflow-tooltip
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <!-- 昵称 -->
        <el-table-column
          show-overflow-tooltip
          prop="user_name"
          label="用户昵称"
        ></el-table-column>
        <!-- ID -->
        <el-table-column
          show-overflow-tooltip
          prop="user_code"
          label="用户编号"
          width="100"
        ></el-table-column>
        <!-- 商品图片 -->
        <el-table-column show-overflow-tooltip label="包裹图片">
          <template #default="{ row }">
            <el-image
              v-if="imgShow"
              :preview-src-list="imageList"
              :src="row.pic"
            ></el-image>
          </template>
        </el-table-column>
        <!-- 订单编号 -->
        <el-table-column
          show-overflow-tooltip
          prop="code"
          label="订单编号"
          width="350"
        ></el-table-column>
        <!-- 运单号 -->
        <el-table-column
          show-overflow-tooltip
          prop="express_code"
          label="运单编号"
          width="230"
        ></el-table-column>
        <!-- 国内单号 -->
        <el-table-column
          show-overflow-tooltip
          prop="old_code"
          label="国内单号"
          width="230"
        ></el-table-column>
        <!-- 包裹状态 -->
        <el-table-column label="状态" align="center">
          <template #default="{ row }">
            <el-tag v-if="row.status == 0" size="small" type="danger">
              未入库
            </el-tag>
            <el-tag v-if="row.status == 1" size="small" type="success">
              已入库
            </el-tag>
            <el-tag v-if="row.status == 2" size="small" class="dabaoStyle">
              待发货
            </el-tag>
            <el-tag v-if="row.status == 3" size="small" type="warning">
              待支付
            </el-tag>
            <el-tag v-if="row.status == 4" size="small" class="fahuoStyle">
              已出库
            </el-tag>
            <el-tag v-if="row.status == 5" size="small" type="primary">
              已查验
            </el-tag>
            <el-tag v-if="row.status == 10" size="small" type="info">
              已签收
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column label="发货方式" align="center">
          <template #default="{ row }">
            <div v-if="row.transport_type == 1" id="">CJ海运</div>
            <div v-if="row.transport_type == 2">CJ空运</div>
            <div v-if="row.transport_type == 3" id="">EMS海运</div>
            <div v-if="row.transport_type == 4" id="">EMS空运</div>
          </template>
        </el-table-column>

        <!-- 订单金额 -->
        <el-table-column
          show-overflow-tooltip
          prop="price"
          label="订单金额"
        ></el-table-column>
        <!-- 订单重量 -->
        <el-table-column
          show-overflow-tooltip
          prop="weight"
          label="订单重量"
        ></el-table-column>
        <!-- 订单备注 -->
        <el-table-column
          show-overflow-tooltip
          prop="remark"
          label="订单备注"
          width="130"
          align="center"
        ></el-table-column>
        <!-- 订单发货方式 -->
        <el-table-column
          show-overflow-tooltip
          prop="shipping_type_name"
          label="发货方式"
          align="center"
        ></el-table-column>
        <!-- 收件人 -->
        <el-table-column
          show-overflow-tooltip
          prop="name"
          label="收件人"
          width="120"
          align="center"
        ></el-table-column>
        <!-- 手机号 -->
        <el-table-column
          show-overflow-tooltip
          prop="phone"
          label="手机号"
          width="130"
          align="center"
        ></el-table-column>

        <el-table-column
          show-overflow-tooltip
          label="创建时间"
          prop="add_time"
          width="170"
          align="center"
          sortable
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          label="操作面板"
          width="220px"
          fixed="right"
          align="center"
        >
          <template #default="{ row }">
            <el-button size="text" @click="handleEdit(row)">编辑</el-button>
            <el-button type="text" style="color:orange;" size="mini" @click="handledetails(row)">
              详情
            </el-button>
            <el-button type="text" style="color: red;" size="mini" @click="handleDelete(row)">
              删除
            </el-button>
            <el-button size="text" v-if="row.status == 2 && row.shipping_type == 4"  @click="back_status(row)">退回入库</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :background="background"
        :current-page="queryForm.page"
        :layout="layout"
        :page-sizes="[20, 40, 60, 100]"
        :page-size="queryForm.limit"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
      <manage-parcel ref="edit" @fetch-data="getnewlist"></manage-parcel>

      <parcel-details ref="edit1" @fetch-data="getnewlist"></parcel-details>
    </div>
  </div>
</template>

<script>
  import {
    orderListZdh,
    orderStatusZdh,
    orderNotice,
    pullingCheck,
    orderDelZdh,
    uploadFileZdh,
    excelStatus,
    seaExcelOut,
    airExcelOut,
    esmSeaExcelOut,
    esmAirExcelOut,
    KorApi_notice,
    Order_pullingCheck,
    kor_order_return
  } from '@/api/xgcApi'
  import { Order_printStatus, Order_printCheck } from '@/api/print'
  import manageParcel from '../components/manageParcel'
  import parcelDetails from '../components/parcelDetails'
  import { baseURL_print, getUploadUrl } from '@/config'
  import { number } from 'echarts'
  export default {
    name: 'ComprehensiveTable',
    components: {
      manageParcel,
      parcelDetails,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        uploadFileUrl: baseURL_print + 'index/index/file',
        height: '65vh',
        isShowFahuo: false,
        isShowQianshou: false,
        pullFormBtn: false,
        doPrintLoading: false, //打印面单loading
        doPreviewLoading: false, //打印预览loading
        printTaskId: 0,
        printers: null, //打印相关
        chooseItem: [], //选中的数据
        defaultPrinter: null, //打印相关
        waybillNO: '0000000000000',
        waybillTemplateURL: baseURL_print + 'admin/index/print',
        imgShow: true,
        socket: null, //
        list: [],
        imageList: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        background: true,
        selectRows: '',
        elementLoadingText: '正在加载...',
        queryForm: {
          page: 1,
          limit: 20,
          status: '',
          type: 1,
          type2: 3,
        },
        value1: '',
        value: '',
        activeName: 'a',
        remindCount: {},
        isShowCode: false,
      }
    },
    // computed: {
    //   height() {
    //     return this.$baseTableHeight()
    //   },
    // },
    created() {
      this.fetchData()
    },

    beforeDestroy() {},
    mounted() {
      // this.doConnect()
    },
    methods: {
      // 状态退回到已入库
      back_status(row){
        this.$confirm('确认将该订单退回至入库状态', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // this.$message({
          //   type: 'success',
          //   message: '退回成功!'
          // });
          var data = {
            order_id:row.id
          }
          console.log(data)
          kor_order_return(data).then(res=>{
            this.$message({
              type: 'success',
              message: '退回成功!'
            });
            this.fetchData()
          }).catch(err=>{
            this.fetchData()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '退回取消'
          });
        });
      },
      laDan() {
        // 批量拉单
        var ids = []
        if (this.chooseItem.length < 1) {
          this.$message({
            type: 'warning',
            message: '请选择拉单数据',
          })
          return
        }
        this.chooseItem.forEach((item, index) => {
          ids.push(item.id)
        })
        var id_String = ids.join(',')
        Order_pullingCheck({ id: id_String }).then((res) => {
          KorApi_notice({ id: id_String }).then((res) => {
            this.$message({
              type: 'success',
              message: '操作成功',
            })
          })
        })
      },
      importFile() {},
      // table，切换方法
      handleClick(tab, event) {
        switch (tab.name) {
          case 'a':
            this.queryForm.status = ''
            this.pullFormBtn = this.isShowFahuo = this.isShowQianshou = false
            break
          case 'b':
            this.queryForm.status = 0
            this.pullFormBtn = false
            this.isShowQianshou = this.isShowFahuo = false
            break
          case 'c':
            this.queryForm.status = 1
            this.pullFormBtn = false
            this.isShowFahuo = this.isShowQianshou = false
            break
          case 'd':
            this.queryForm.status = 2
            this.pullFormBtn = true
            this.isShowQianshou = this.isShowFahuo = false

            break
          case 'e':
            this.queryForm.status = 3
            this.pullFormBtn = this.isShowFahuo = true
            this.isShowQianshou = false
            break
          case 'f':
            this.queryForm.status = 4
            this.pullFormBtn = true
            this.isShowQianshou = this.isShowFahuo = false
            break
          case 'g':
            this.queryForm.status = 5
            this.pullFormBtn = true
            this.isShowFahuo = this.isShowQianshou = false
            break
          case 'h':
            this.queryForm.status = 6
            this.pullFormBtn = this.isShowQianshou = true
            this.isShowFahuo = false
            break
          case 'i':
            this.queryForm.status = 7
            this.pullFormBtn = true
            this.isShowFahuo = this.isShowQianshou = false
            break
          case 'j':
            this.queryForm.status = 10
            this.pullFormBtn = true
            this.isShowFahuo = this.isShowQianshou = false
            break
          case 'k':
            this.queryForm.status = 8
            this.isShowFahuo = this.isShowQianshou = this.pullFormBtn = false
            break
        }
        this.queryForm.page = 1
        this.fetchData()
      },
      getnewlist() {
        this.fetchData()
      },
      tableSortChange() {
        const imageList = []
        this.$refs.tableSort.tableData.forEach((item, index) => {
          imageList.push(item.img)
        })
        this.imageList = imageList
      },
      setSelectRows(val) {
        this.selectRows = val
        this.chooseItem = val
      },
      handleAdd() {
        this.$refs['edit'].showEdit({ country: 1 })
      },
      // 点击修改编辑
      handleEdit(row) {
        this.$refs['edit'].showEdit(row)
      },

      // 点击详情
      handledetails(row) {
        this.$refs['edit1'].showEdit(row)
      },

      // 批量导出
      exportForm(e) {
        if (this.selectRows.length > 0) {
          this.$baseConfirm('确定导出已选', null, async () => {
            const ids = this.selectRows.map((item) => item.id).join()
            if (e == 1) {
              const { data } = await airExcelOut({ id: ids })
              const fileUrl = getUploadUrl + data
              window.open(fileUrl)
              this.$message({ type: 'success', message: '导出成功' })
            }
            if (e == 2) {
              const { data } = await seaExcelOut({ id: ids })
              const fileUrl = getUploadUrl + data
              window.open(fileUrl)
              this.$message({ type: 'success', message: '导出成功' })
            }
            if (e == 3) {
              const { data } = await esmAirExcelOut({ id: ids })
              const fileUrl = getUploadUrl + data
              window.open(fileUrl)
              this.$message({ type: 'success', message: '导出成功' })
            }
            if (e == 4) {
              const { data } = await esmSeaExcelOut({ id: ids })
              const fileUrl = getUploadUrl + data
              window.open(fileUrl)
              this.$message({ type: 'success', message: '导出成功' })
            }
          })
        } else {
          this.$baseMessage('未选中任何行', 'error')
          return false
        }
      },
      async handleChange(file, fileList) {
        if (file.response != undefined) {
          var strurl = file.response
          strurl = strurl.replace(/\\/g, '/')
          const { code, data, msg } = await uploadFileZdh({ file: strurl })
          if (code == 200) {
            this.$message({ type: 'success', message: '导入成功' })
            this.fetchData()
          } else {
            this.$baseMessage('失败')
            return false
          }
        }
      },

      async excelStatusChange(file, fileList) {
        if (file.response != undefined) {
          var strurl = file.response
          strurl = strurl.replace(/\\/g, '/')
          const { code, data, msg } = await excelStatus({ file: strurl })
          if (code == 200) {
            this.$message({ type: 'success', message: '导入成功' })
            this.fetchData()
          } else {
            this.$baseMessage('失败')
            return false
          }
        }
      },
      // 删除
      handleDelete(row) {
        if (row.id) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await orderDelZdh({
              id: row.id,
            })
            this.$baseMessage(msg, 'success')
            this.fetchData()
          })
        } else {
          if (this.selectRows.length > 0) {
            const ids = this.selectRows.map((item) => item.id).join()
            this.$baseConfirm('你确定要删除选中项吗', null, async () => {
              const { msg } = await orderDelZdh({
                id: ids,
              })
              this.$baseMessage(msg, 'success')
              this.fetchData()
            })
          } else {
            this.$baseMessage('未选中任何行', 'error')
            return false
          }
        }
      },

      // 批量打包。批量发货、批量签收、批量审核拒绝
      setQuery(e) {
        if (this.selectRows.length > 0) {
          if (e == 2) {
            this.$baseConfirm('确定发货已选', null, async () => {
              const ids = this.selectRows.map((item) => item.id).join()
              const { msg } = await orderStatusZdh({ order_id: ids, status: e })
              this.$message({ type: 'success', message: '发货成功' })
              this.fetchData()
            })
          }
          if (e == 5) {
            this.$baseConfirm('确定查验已选', null, async () => {
              const ids = this.selectRows.map((item) => item.id).join()
              const { msg } = await orderStatusZdh({ order_id: ids, status: e })
              this.$message({ type: 'success', message: '查验成功' })
              this.fetchData()
            })
          }
          if (e == 10) {
            this.$baseConfirm('确定签收已选', null, async () => {
              const ids = this.selectRows.map((item) => item.id).join()
              const { msg } = await orderStatusZdh({ order_id: ids, status: e })
              this.$message({ type: 'success', message: '签收成功' })
              this.fetchData()
            })
          }
        } else {
          this.$baseMessage('未选中任何行', 'error')
          return false
        }
      },
      // // 批量拉单
      // pullForm(e) {
      //   if (this.selectRows.length > 0) {
      //     const ids = this.selectRows.map((item) => item.id).join()
      //     this.$baseConfirm('你确定要拉取选中项吗', null, async () => {
      //       const { data, code } = await pullingCheck({ id: ids, type: e })
      //       if (code == 200) {
      //         const { msg } = await orderNotice({ id: ids, type: e })

      //         this.$baseMessage(msg, 'success')
      //       } else {
      //         this.$baseMessage(msg, 'error')
      //       }
      //     })
      //   } else {
      //     this.$baseMessage('未选中任何行', 'error')
      //     return false
      //   }
      // },
      // 分页
      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      // 分页
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      // 查询
      handleQuery() {
        this.queryForm.page = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data } = await orderListZdh(this.queryForm)
        this.list = data.list
        this.remindCount = data.statusCount
        this.total = data.count
        const imageList = []
        data.list.forEach((item, index) => {
          imageList.push(item.pic)
        })
        this.imageList = imageList
        setTimeout(() => {
          this.listLoading = false
        }, 500)
      },

      /**
       * @name wqz
       * @function 面单打印相关方法
       * @time 2024年4月18日09:32:42
       */
      // doConnect() {
      //   var that = this
      //   this.socket = new WebSocket('ws://127.0.0.1:13528')
      //   var result = '打印成功'
      //   var tmptaskID = '0'
      //   var that = this
      //   // 打开Socket
      //   that.socket.onopen = function (event) {
      //     // 监听消息
      //     that.socket.onmessage = function (event) {
      //       var data = JSON.parse(event.data)
      //       that.printers = data.printers
      //       that.defaultPrinter = data.defaultPrinter
      //       if (data.cmd == 'notifyPrintResult') {
      //         // 打印出现问题
      //         var result = '打印成功'
      //         if (data.taskID != tmptaskID) {
      //           for (var i = 0; i < data.printStatus.length; i++) {
      //             if (data.printStatus[i].status == 'failed') {
      //               result = '打印失败'
      //               that.$message({ type: 'error', message: '打印失败' })
      //               break
      //             } else if (data.printStatus[i].status == 'canceled') {
      //               that.$message({ type: 'error', message: '取消打印任务' })
      //               result = '取消打印任务'
      //               break
      //             }
      //           }
      //           tmptaskID = data.taskID
      //         }
      //         that.doPreviewLoading = false
      //         that.doPrintLoading = false
      //       } else if (data.cmd == 'print') {
      //         // 这里面写打印/预览成功之后的回调
      //         that.doPreviewLoading = false
      //         that.doPrintLoading = false
      //         if (data.previewURL) {
      //           // 预览成功打开pdf文件url
      //           window.open(data.previewURL)
      //         } else {
      //           // 打印成功
      //           // var date =this.chooseItem
      //           var ids = []
      //           that.chooseItem.forEach((item, index) => {
      //             ids.push(item.id)
      //           })
      //           var date = {
      //             id: ids.join(','),
      //           }
      //           if (data.status != 'success') {
      //             that.$message({ type: 'error', message: data.msg })
      //           } else {
      //             Order_printStatus(date).then((res) => {
      //               // 这个是打印成功回调
      //               that.$message({ type: 'success', message: '打印成功' })
      //             })
      //           }
      //         }
      //       }
      //     }
      //     // 监听Socket的关闭
      //     that.socket.onclose = function (event) {
      //       that.$message({
      //         type: 'error',
      //         message: '打印机失去联系，请刷新网页重新连接',
      //       })
      //     }
      //   }
      //   that.socket.onerror = function (event) {
      //     that.$message({ type: 'error', message: '打印机出现问题,请联系客服' })
      //   }
      // },

      // 打印预览
      // doPreview() {
      //   if (this.selectRows.length > 0) {
      //     const ids = this.selectRows.map((item) => item.id).join(',')
      //     this.$baseConfirm('你确定打印预览选中项吗', null, async () => {
      //       var data = {
      //         id: ids,
      //       }
      //       Order_printCheck(data).then((res) => {
      //         var printData = this.getPrintData()
      //         printData.task.preview = true
      //         this.doPreviewLoading = true
      //         this.socket.send(JSON.stringify(printData))
      //       })
      //     })
      //   } else {
      //     this.$baseMessage('未选中任何行', 'error')
      //     return false
      //   }
      // },
      //打印电子面单
      // doPrint() {
      //   if (this.selectRows.length > 0) {
      //     const ids = this.selectRows.map((item) => item.id).join()
      //     this.$baseConfirm('你确定打印选中项吗', null, async () => {
      //       var data = {
      //         id: ids,
      //       }
      //       Order_printCheck(data).then((res) => {
      //         var printData = this.getPrintData()
      //         this.doPrintLoading = true
      //         this.socket.send(JSON.stringify(printData))
      //       })
      //     })
      //   } else {
      //     this.$baseMessage('未选中任何行', 'error')
      //     return false
      //   }
      // },
      // 组装打印机需要的参数
      // getPrintData() {
      //   var dataArr = []
      //   this.chooseItem.forEach((item, ind) => {
      //     // if (item.id == 1727) {
      //     //   // 模拟圆通
      //     // }
      //     dataArr.push({
      //       documentID: this.waybillNO,
      //       contents: [
      //         //电子面单部分
      //         {
      //           templateURL: this.waybillTemplateURL + '?id=' + item.id,
      //           signature: 'MD:kw4V6Gtj/7ZasLyotby+HA==',
      //           //电子面单数据
      //         },
      //       ],
      //     })
      //   })
      //   this.printTaskId = parseInt(1000 * Math.random())
      //   var request = {
      //     cmd: 'print',
      //     requestID: '12345678901234567890',
      //     version: '1.0',
      //     task: {
      //       taskID: '' + this.printTaskId,
      //       preview: false,
      //       printer: this.defaultPrinter,
      //       notifyMode: 'allInOne',
      //       previewType: 'pdf',
      //       documents: dataArr,
      //     },
      //   }
      //   return request
      // },
    },
  }
</script>

<style scoped>
  :deep .right-panel {
    display: flex;
    align-items: center;
  }
  .demo-tabs .custom-tabs-label span {
    vertical-align: super;
    margin-left: 4px;
  }
  ::v-deep(.el-badge__content.is-fixed) {
    top: 11px;
    right: 17px;
  }

  .table-container {
    padding: 15px;
    padding-top: 30px;
    box-sizing: border-box;
    border-left: 1px solid #e4e7ed;
    border-right: 1px solid #e4e7ed;
    border-bottom: 1px solid #e4e7ed;
  }
  .el-tabs__item {
    width: 200px; /* 设置宽度为200px */
  }
  .el-badge {
    width: 60px !important;
  }

  ::v-deep(.el-tabs__header) {
    margin: 0 !important;
  }

  ::v-deep(.el-tabs__header span) {
    font-weight: bold;
  }
  .demo-table-expand {
    font-size: 0;
    padding-left: 150px;
    box-sizing: border-box;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 30%;
  }
  .dabaoStyle {
    background: #ffddaa;
    color: #999;
    border: 1px solid #fd5;
  }
  .fahuoStyle {
    background: #99dddd;
    color: #999;
    border: 1px solid 00FFFF;
  }
  .zhuanyunStyle {
    background: #ffffaa;
    color: #999;
    border: 1px solid FFFF00;
  }
</style>
