@import "~@/styles/variables.scss";
.guige_item {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 15px;
  margin-bottom: 20px;
}
.guige_item_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.guige_item_bottom {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
:deep .el-input--small .el-input__inner {
  width: 100%;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/*  :deep .el-dialog__body {
  height: 63vh;
  overflow: hidden;
  overflow-y: scroll;
} */
.div_form {
  height: 80vh;
  overflow: hidden;
  overflow-y: scroll;
}
:deep .ql-container {
  height: 400px;
}
